import React, {useState, useEffect} from 'react';
import {Button, Modal, Form, Row, Col, Jumbotron, InputGroup, OverlayTrigger, Tooltip, Table, Tabs, Tab} from 'react-bootstrap';
import {useGlobalState, setGlobalState} from '../GlobalState';
import * as Constants from '../constants/constants';
import axios from 'axios';
import {deleteCookie} from '../cookie';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PositionAccordion from './positionAccordion';
import CopyPosition from './copyPosition';
import moment from 'moment';

export default function ModalCampagne(props) {
    document.onkeydown = function(evt) {
        evt = evt || window.event;
        if (evt.keyCode == 27) {
            if(props.dataSource.id == undefined){
                setModalConfirmSave(loadConfirmSave());
            }
            else{
                closeAll();
            }
        }
    };

    const [customers, setCustomers] = useState();
    const [sectors, setSectors] = useState();
    const [agency, setAgency] = useState();
    const [status, setStatus] = useState();
    const [payment, setPayment] = useState()
    const [secondarySeller, setSecondarySeller] = useState();
    const [fullSecondarySeller, setFullSecondarySeller] = useState();
    const [fullCustomers, setFullCustomers] = useState();
    const [fullAgency, setFullAgency] = useState();
    const [paymentMethods, setPaymentMethods] = useState();
    const [positionsFixed, setPositionsFixed] = useState();
    const [positionsUnfixed, setPositionsUnfixed] = useState();
    const [fullPositions, setFullPositions] = useState();
    const [positionBoxFixed, setPositionBoxFixed] = useState([]);
    const [positionBoxUnfixed, setPositionBoxUnfixed] = useState([]);
    const [modalDeletePosition, setModalDeletePosition] = useState();
    const [modalAdManager, setModalAdManager] = useState();
    const [modalCopyPosition, setModalCopyPosition] = useState();
    const [modalConfirmSave, setModalConfirmSave] = useState();
    const [venditoreSecondarioVisible, setVenditoreSecondarioVisible] = useState(false);
    const [statistics, setStatistics] = useState(null);
    const [modalSaveCampaign, setModalSaveCampaign] = useState(null);
    const [key, setKey] = useState(1);
    const [fileGOdata, setFileGoData] = useState();
    
    let tempAgencyInvoice = '';

    let defaultPositionElement = {
        fixed:[],
        unfixed:[]
    }
    const [positionElement, setPositionElement] = useState(defaultPositionElement);
    let defaultPaymenObj = {
        direct_customer:null,
        agency:null,
        payment_type:null,
        sector:null,
        invoice_to_direct_customer:null,
        invoice_to_agency:null
    }
    const [paymenObj, setPaymenObj] = useState(defaultPaymenObj);
    let globalState = useGlobalState();

    useEffect(() => {
        let posfix =[];
        let posunfix =[];
        positionElement.fixed.map((item,index)=>{
            posfix.push(loadPositions(true,item,index));
        })
        positionElement.unfixed.map((item,index)=>{
            posunfix.push(loadPositions(false,item,index));
        })
    
        setPositionBoxFixed(posfix);
        setPositionBoxUnfixed(posunfix);
    },[positionElement]);

    useEffect(() => {
        if(props.dataSource != null){
            if(props.dataSource.positions !=null){
                setPositionElement(props.dataSource.positions)
            }
        }
    },[props.dataSource.positions]);

    useEffect(() => {
        if(process.env.REACT_APP_CUSTOM != null){
            if(props.dataSource != null){
                if(props.dataSource.id !=null){
                    if(props.dataSource.states_campaign != null){
                        // if(props.dataSource.states_campaign.id == 6){
                        //     setFileGoData(generateGoData());
                        // }
                    }
                }
            }
        }
    },[props.dataSource.id]);

    useEffect(() => {
        if(!globalState.groups.includes(Constants.underground)){
            if(props.dataSource != null){
                if(props.dataSource.payment_type !=null){
                    loadPaymentMethods(null, null, null);
                }
            }
        } 
    },[props.dataSource.payment_type]);

    useEffect(() => {
        if(props.dataSource != null){
            if(props.dataSource.secondary_seller!=null)
                setVenditoreSecondarioVisible(true);
        }
    },[props.dataSource.secondary_seller]);

    useEffect(() => {
        if(props.dataSource != null){
            if(props.dataSource.states_campaign!=null)
                document.getElementById('states_campaign').value = props.dataSource.states_campaign.id
        }
    },[props.dataSource.states_campaign]);

    useEffect(() => {
        if(!globalState.groups.includes(Constants.underground)){
            if(props.dataSource != null){
                if(props.dataSource.direct_customer !=null){
                    serviceApiGetSettori((Constants.getSettoriPerIdCliente + '?size=9999').replace('*', props.dataSource.direct_customer.id),'GET')
                }
            }
        }
    },[props.dataSource.direct_customer]);

    useEffect(() => {
        if(props.dataSource != null){
            setSectors(populateSectors(null))
        }
    },[props.dataSource.sector]);

    useEffect(() => { 
        loadCustomer();
        loadAgency();
        loadStatus();
        loadSecondarySeller();
        serviceApiGetPayment(Constants.getPagamenti, 'GET');
        serviceApiGetPosizioni(Constants.getPosition + '?is_visible=True&is_common=True&size=9999', 'GET');
    },[]);

    useEffect(()=>{
        if(props.dataSource != null){
            if(!props.isEdit){
                if(props.dataSource.admanager_report != null && props.dataSource.states_campaign != null){
                    if(props.dataSource.states_campaign.id == 6 || props.dataSource.states_campaign.id == 4){
                        setStatistics(loadStatistics(props.dataSource.admanager_report));
                    }
                }
            }
        }
    },[props.dataSource.admanager_report])
    
    const closeAll = () =>{
        props.closeModal();
        setStatistics(null);
        setPositionBoxFixed(); 
        setPositionBoxUnfixed();
        props.reloadTable();
        setPaymenObj(defaultPaymenObj);
        setPaymentMethods();
        setModalDeletePosition();
        setModalAdManager();
        setModalSaveCampaign();
        setVenditoreSecondarioVisible(false);
        setKey(1);
        setFileGoData();
    }

    const verifyDatePosition = (start, end) =>{     
        let originDateStart = start.split('T');
        let originDateEnd = end.split('T');
        let sDate = originDateStart[0];
        let eDate = originDateEnd[0];
        let sHour = originDateStart[1];
        let eHour = originDateEnd[1];
        if(parseInt(sDate.replace(/-/g,''))>parseInt(eDate.replace(/-/g,'')))
            return false
        else if(parseInt(sDate.replace(/-/g,'')) == parseInt(eDate.replace(/-/g,''))){
            if(parseInt(sHour.replace(/T/g,'').replace(/:/g,''))>=parseInt(eHour.replace(/T/g,'').replace(/:/g,''))){
                return false;
            }
        }
    }

    const loadConfirmSave = () =>{
        return(
            <Modal onHide={(e)=>{return}} style={{backgroundColor:'rgba(0,0,0,0.3)'}} show={true} centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Constants.confermaSalvataggioCampagna}
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                            <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>setModalConfirmSave()}>
                                <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                            </Button>
                        </OverlayTrigger>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Constants.exitWithoutSave}
                </Modal.Body>
                <Modal.Footer>
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.nonSalvare}</Tooltip>}>
                        <Button variant="secondary" onClick={()=>{closeAll(); setModalConfirmSave();}}>{Constants.nonSalvare}</Button>
                    </OverlayTrigger>
                    {<OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.salva}</Tooltip>}>
                        <Button variant="success" onClick={(e)=>{saveData(e, 2); setModalConfirmSave();}}>{Constants.salva}</Button>
                    </OverlayTrigger> }
                </Modal.Footer>
            </Modal>
        );
    }



    // const generateGoData = () =>{
    //     let invoice;
    //     if(props.dataSource.invoice_to_agency != null)
    //         invoice = props.dataSource.invoice_to_agency;
    //     else
    //         invoice = props.dataSource.invoice_to_direct_customer;

    //     let GOobj = '';
    //     GOobj +='"T";"'+moment(props.dataSource.end_date, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY")+'";"999";"10";"";"'+invoice.business_name+'";"";"'+invoice.address+'";"";"";"";"IT";"'+invoice.vat_number+'";"";"EUR";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"'+invoice.telephone_number+'";"";"";"'+props.dataSource.amount+'";"";"";""\n';
    //     GOobj +='"I";"";"";""\n';
    //     GOobj +='"R";"";"";"";"";"";""\n';
    //     GOobj +='"C";"";"";""\n';
    //     GOobj +='"V";"'+moment(props.dataSource.beginning_date, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY")+'";"'+moment(props.dataSource.end_date, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY")+'"\n';
    //     GOobj +='"A";"";"";""';
    //     return GOobj;
    // }


    const saveData = (e, saveType) =>{
        e.preventDefault();
        let tempObj = {...props.dataSource}
        tempObj.description = document.getElementById('description').value;
        tempObj.referent = document.getElementById('referent').value;
        tempObj.note = document.getElementById('nota').value;
        tempObj.note_cliente = document.getElementById('nota_cliente').value;
        tempObj.states_campaign = document.getElementById('states_campaign') ==null?null:parseInt(document.getElementById('states_campaign').value);
        tempObj.discount = document.getElementById('sconto').value == ""?0:document.getElementById('sconto').value;
        tempObj.discount_reason = document.getElementById('motivazione_sconto').value;
        tempObj.purchase_order = document.getElementById('purchase_order').value;

        if(document.getElementById('checkVenditoreSecondario').checked){
            if(document.getElementById('secondarySeller').value != '')
                tempObj.secondary_seller = document.getElementById('secondarySeller').value;
            else
                return setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.requiredFieldError + " " + Constants.venditoreSecondario, typeAlert:'warning'});

            if(document.getElementById('percentualeVenditoreSecondario').value != '')
                tempObj.secondary_seller_percentage = document.getElementById('percentualeVenditoreSecondario').value;
            else
                tempObj.secondary_seller_percentage = 50;
            
        }
            
        let verifyPosition = [...positionElement.fixed, ...positionElement.unfixed];
        let verified = null;
        let dateOk = null;

        if(!verifyPosition.length>0){
            return setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.requiredFieldError + " " + Constants.position, typeAlert:'warning'});
        }

        positionElement.fixed.map((item, index)=>{
            if(item.position == null || item.position==""){
                verified = Constants.positionsRequiredField + " " + Constants.fissa + " " + (index + 1)  + " : " + Constants.posizione;
            }
            if(item.beginning_date ==null){
                verified = Constants.positionsRequiredField + " " + Constants.fissa + " " + (index + 1)  + " : " + Constants.dataInizio;
            }
            if(item.end_date ==null){
                verified = Constants.positionsRequiredField + " " + Constants.fissa + " " + (index + 1)  + " : " + Constants.dataFine;
            }
            if(item.beginning_date !=null && item.end_date !=null){
                if(verifyDatePosition(item.beginning_date, item.end_date)==false){
                    verified = Constants.dateError + " " + Constants.nellaPosizione + " " + Constants.fissa + " " + (index + 1) ;
                }
            }   
            if(item.forfait_sale == null || item.forfait_sale ===""){
                verified = Constants.positionsRequiredField + " " + Constants.fissa + " " + (index + 1)  + " : " + Constants.forfaitVendita;
            } 
            if(item.forfait_back == null || item.forfait_back ===""){
                item.forfait_back = null;
            } 
            if(item.impression == null || item.impression ===""){
                item.impression = 0;
            } 
        })

        if(dateOk != null)
            return setGlobalState({showAlert:true, messageAlert:dateOk, typeAlert:'warning'});
        if(verified != null)
            return setGlobalState({showAlert:true, messageAlert:verified, typeAlert:'warning'});

        positionElement.unfixed.map((item, index)=>{
            if(item.position == null || item.position==""){
                verified = Constants.positionsRequiredField + " " + Constants.nonFissa + " " + (index + 1) + " : " + Constants.posizione;
            }
            if(item.beginning_date ==null){
                verified = Constants.positionsRequiredField + " " + Constants.nonFissa + " " + (index + 1) + " : " + Constants.dataInizio;
            }
            if(item.end_date ==null){
                verified = Constants.positionsRequiredField + " " + Constants.nonFissa + " " + (index + 1) + " : " + Constants.dataFine;
            }
            if(item.beginning_date !=null && item.end_date !=null){
                if(verifyDatePosition(item.beginning_date, item.end_date)==false){
                    verified = Constants.dateError  + " " + Constants.nellaPosizione + " " + Constants.nonFissa + " " + (index + 1);
                }
            }
            if(item.cpm_sale == null || item.cpm_sale ===""){
                verified = Constants.positionsRequiredField + " " + Constants.nonFissa + " " + (index + 1) + " : " + Constants.cpmVendita;
            } 

            if(item.cpm_back == null || item.cpm_back ===""){
                item.cpm_back = null;
            } 
            if(item.impression == null || item.impression ===""){
                item.impression = 0;
            } 
        })

        if(dateOk != null)
            return setGlobalState({showAlert:true, messageAlert:dateOk, typeAlert:'warning'});
        if(verified != null)
            return setGlobalState({showAlert:true, messageAlert:verified, typeAlert:'warning'});


        let paymentVerified = false;
        if(document.getElementById('switch_' + Constants.cliente)!=null){
            if(document.getElementById('switch_' + Constants.cliente).checked)
                paymentVerified=true;
        }

        if(document.getElementById('switch_' + Constants.agenzia)!=null){
            if(document.getElementById('switch_' + Constants.agenzia).checked)
                paymentVerified=true;
        }       

        if(!paymentVerified){
            if(document.getElementById('slider_' + Constants.agenzia)!=null)
                document.getElementById('slider_' + Constants.agenzia).classList.add('setErrorField');
            
            if(document.getElementById('slider_' + Constants.cliente)!=null)
                document.getElementById('slider_' + Constants.cliente).classList.add('setErrorField');
    
            return setGlobalState({showAlert:true, messageAlert:Constants.paymentRequired, typeAlert:'warning'});
        }

            
        tempObj.positions = verifyPosition;

        if(document.getElementById('customers').value!="" && document.getElementById('customers').value!=null){
            tempObj.direct_customer = parseInt(document.getElementById('customers').value);
            if(document.getElementById('sectors').value!="")
                tempObj.sector = parseInt(document.getElementById('sectors').value);
            else{
                document.getElementById('sectors').classList.add('setErrorField');
                return setGlobalState({showAlert:true, messageAlert:Constants.sectorRequired, typeAlert:'warning'});
            }
                
        }
        else{
            tempObj.direct_customer = null;
            tempObj.sector = null;
            return setGlobalState({showAlert:true, messageAlert:Constants.customersRequired, typeAlert:'warning'});
        }
        
        tempObj.is_direct = document.getElementById('diretta').checked;

        if(document.getElementById('diretta').checked){
            tempObj.agency = null;
            tempObj.invoice_to_agency = null;
        }
        else{
            if(document.getElementById('agency').value!=""){
                tempObj.agency = parseInt(document.getElementById('agency').value);
                if(document.getElementById('fattura-agenzia').value == ''){
                    document.getElementById('fattura-agenzia').classList.add('setErrorField');
                    return setGlobalState({showAlert:true, messageAlert:Constants.invoiceAgencyRequired, typeAlert:'warning'});
                }
                if(document.getElementById('media_center_category').value == ''){
                    document.getElementById('media_center_category').classList.add('setErrorField');
                    return setGlobalState({showAlert:true, messageAlert:Constants.categoriaCentromediaRequired, typeAlert:'warning'});
                }
                else{
                    tempObj.media_center_category = parseInt(document.getElementById('media_center_category').value);
                }
            }
            else{
                tempObj.agency = null;
                tempObj.invoice_to_agency = null;
                tempObj.media_center_category = null;
            }
            
            if(document.getElementById('switch_' + Constants.agenzia) != null){
                if(document.getElementById('switch_' + Constants.agenzia).checked){
                    tempObj.invoice_to_direct_customer = null
                    tempObj.invoice_to_agency = parseInt(document.getElementById('fattura-agenzia').value);
                    tempObj.payment_type = parseInt(document.getElementById('payment_' + Constants.agenzia).value)
                }
            }
        }
        
        
        if(document.getElementById('switch_' + Constants.cliente) != null){
            if(document.getElementById('switch_' + Constants.cliente).checked){
                tempObj.invoice_to_direct_customer = parseInt(document.getElementById('customers').value)
                tempObj.invoice_to_agency = null;
                tempObj.payment_type = parseInt(document.getElementById('payment_' + Constants.cliente).value)
            }
        }

    

        tempObj.user = parseInt(globalState.user_id);
        tempObj.user_latest_modification = parseInt(globalState.user_id);

        let lastVerify = true;

        verifyPosition.map((item, index)=>{
            if(moment(item.beginning_date, "YYYY-MM-DDTHH:mm").format("YYYYMMDD") < moment(new Date).format("YYYYMMDD"))
                lastVerify = false;
        })

        if(!lastVerify)
            setModalSaveCampaign(loadModalSave(tempObj, saveType));
        else
            confirmSave(tempObj, saveType);
    }

    const loadModalSave = (tempObj, saveType) =>{
        return(
            <Modal onHide={(e)=>{return}} style={{backgroundColor:'rgba(0,0,0,0.3)'}} show={true} centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Constants.confermaSalvataggioCampagna}
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                            <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>setModalSaveCampaign()}>
                                <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                            </Button>
                        </OverlayTrigger>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Constants.saveCampaingMessage}
                </Modal.Body>
                <Modal.Footer>
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.annulla}</Tooltip>}>
                        <Button variant="secondary" onClick={()=>setModalSaveCampaign()}>{Constants.annulla}</Button>
                    </OverlayTrigger>
                    {<OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.salva}</Tooltip>}>
                        <Button variant="success" onClick={()=>confirmSave(tempObj, saveType)}>{Constants.salva}</Button>
                    </OverlayTrigger> }
                </Modal.Footer>
            </Modal>
        );
    }

    const confirmSave = (tempObj, saveType) =>{
        if(tempObj.id != null)
            serviceApiSendData(tempObj, Constants.updateCampagne.replace('*', tempObj.id), 'PUT', saveType);
        else
            serviceApiSendData(tempObj, Constants.insertCampagne, 'POST', saveType);
    }

    const loadCustomer = () =>{
        serviceApiGetClienti(Constants.getClientiDiretti + '?size=9999', 'GET')
    }

    const loadAgency = () =>{
        serviceApiGetAgency(Constants.getAgenzie + '?size=9999', 'GET')
    }

    const loadSecondarySeller = () =>{
        serviceApiGetSecondarySeller(Constants.getUtentiAttivi + '?is_seller=true&size=9999', 'GET')
    }

    const loadStatus = () =>{
        serviceApiGetStatus(Constants.getStatus, 'GET')
    }

    const populateAgency = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.description}</option>;
        })
    }

    const populateSecondarySeller = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.first_name + " " + item.last_name}</option>;
        })
    }

    const populateStatus = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.description}</option>;
        })
    }
    

    const populateCustomers = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.description}</option>;
        })
    }

    const populateSectors = (obj) =>{
        if(obj==null)
            return "";
        else
            return obj.sectors.map((item, index) => {
                return <option key={index} value={item.id}>{item.description}</option>;
            })
    }

    const populatePositions = (obj, mostUsed) =>{
        let posFix = [];
        let posUnfix = [];
        mostUsed.results.map((item, index) => {
            if(item.type.fixed == true)
                posFix.push(<option key={index+'_most'} value={item.id}>{item.site.description + " / " + item.area.description + " / " + item.type.description}</option>);
            else
                posUnfix.push(<option key={index+'_most'} value={item.id}>{item.site.description + " / " + item.area.description + " / " + item.type.description}</option>);
        })

        if(posFix.length>0)
            posFix.push(<option disabled="disabled" key={''} value={''}>{'---- ' + Constants.mostUsed + ' ----'}</option>);
        if(posUnfix.length>0)
            posUnfix.push(<option disabled="disabled" key={''} value={''}>{'---- ' + Constants.mostUsed + ' ----'}</option>);
        
        obj.results.map((item, index) => {
            if(item.type.fixed == true)
                posFix.push(<option key={index} value={item.id}>{item.site.description + " / " + item.area.description + " / " + item.type.description}</option>);
            else
                posUnfix.push(<option key={index} value={item.id}>{item.site.description + " / " + item.area.description + " / " + item.type.description}</option>);
        })
        
        setPositionsFixed(posFix);
        setPositionsUnfixed(posUnfix);
    }

    const populatePayment = (obj) =>{
        return obj.results.map((item, index) => {
            return <option key={index} value={item.id}>{item.description}</option>;
        })
    }

    const directChange = (e) =>{
        if(e.target.checked){
            document.getElementById('agency').value='';
            document.getElementById('media_center_category').value = '';
            document.getElementById('container_media_center_category').style.display = 'none';
        }
    }

    const agencyChange = (e) =>{
        if(e.target.value !== ''){
            document.getElementById('diretta').checked = false; 
            tempAgencyInvoice = e.target.value;
            document.getElementById('container_media_center_category').style.display = 'block';
        } 
        else{
            document.getElementById('media_center_category').value = '';
            document.getElementById('container_media_center_category').style.display = 'none';
            tempAgencyInvoice = '';
        }
        loadPaymentMethods(null, null, true);
    }

    const categoriaCentromedia = (e) =>{
        if(e.target.value !== ''){
            document.getElementById('diretta').checked = false; 
        } 
        loadPaymentMethods(null, null, true);
    }

    const verifyDateModal = () =>{     
        let sDate = document.getElementById('startDateModal');
        let eDate = document.getElementById('endDateModal');
        if(parseInt(sDate.value.replace(/-/g,'').replace(/T/g,'').replace(/:/g,''))>parseInt(eDate.value.replace(/-/g,'').replace(/T/g,'').replace(/:/g,''))){
            //sDate.value=""
            eDate.value="";
            setGlobalState({showAlert:true, messageAlert:Constants.dateError, typeAlert:'warning'});
        }
    }

    const changePayment =(e)=>{
        if(e.target.id=='switch_' + Constants.cliente){
            if(document.getElementById('switch_' + Constants.agenzia) != null)
                document.getElementById('switch_' + Constants.agenzia).checked = false
        }
        else{
            if(document.getElementById('switch_' + Constants.cliente) != null)
                document.getElementById('switch_' + Constants.cliente).checked = false
        }
    }

    const loadStatistics = (data) =>{
        return(
            <Table striped bordered hover>
                {data.map((item, index)=>{
                    return(
                        <tbody key={index}>
                            <tr>
                                <th colSpan="3" style={{backgroundColor:'#262b40', color:'#007bff'}}>{item.LINE_ITEM_NAME}</th>
                            </tr>
                            <tr>
                                <td>{moment(item.LINE_ITEM_START_DATE_TIME, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY - HH:mm")}</td>
                                <td colSpan="2">{moment(item.LINE_ITEM_END_DATE_TIME, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY - HH:mm")}</td>
                            </tr>
                            <tr>
                                <th>{Constants.impression}</th>
                                <th>{Constants.click}</th>
                                <th>{Constants.ctr}</th>
                            </tr>
                            <tr>
                                <td>{item.AD_SERVER_IMPRESSIONS}</td>
                                <td>{item.AD_SERVER_CLICKS}</td>
                                <td>{item.AD_SERVER_CTR}</td>
                            </tr>
                        </tbody>  
                    );     
                })}
            </Table>
        );
    }
     
    const createPaymentCard = (obj, val, categoryName, firstLoad, firstCheck) =>{
        if(val !== "" && val != null){
            if(categoryName == Constants.agenzia){
                if(document.getElementById('diretta').checked)
                    return null
            }
            if(obj.results.length>0){
                return obj.results.map((item, index)=>{
                    if(item.id == val){
                        if(item.payment_type != null){
                            //&& item.business_name!= null <--rimosso questo controllo
                            return(   
                                <Col key ={index}>
                                    <Jumbotron variant="success" style={{padding:15, height:'100%'}}>
                                        <label className="switch" style={{position:'absolute', right:30, top:10}}>
                                            <input defaultChecked={firstCheck == categoryName? true: false} onChange={(e)=>changePayment(e)} disabled={!props.isEdit} id={'switch_' + categoryName} type="checkbox"/>
                                            <span id={'slider_' + categoryName} className="slider round"></span>
                                        </label>
                                        <h5>{categoryName}</h5>
                                        <br/>
                                        {categoryName == Constants.agenzia?
                                                <>
                                                    <Form.Label>{Constants.agenzia + ' ' + Constants.diFatturazione}</Form.Label>
                                                    <Form.Control onChange={(e)=>{loadPaymentMethods(categoryName, e.target.value, false); tempAgencyInvoice = e.target.value;}} defaultValue={props.dataSource.invoice_to_agency!=null && firstLoad == null?props.dataSource.invoice_to_agency.id:tempAgencyInvoice} id="fattura-agenzia" as="select" disabled={!props.isEdit}>
                                                        <option value="" disabled={true}>{Constants.seleziona}</option>
                                                        {agency}
                                                    </Form.Control>
                                                </>
                                            :
                                                null
                                        } 
                                       <br/>
                                        
                                        {categoryName == Constants.cliente?
                                            <>
                                                <Form.Label>{Constants.business_name}</Form.Label>
                                                <Form.Control id={'ragione_sociale_' + categoryName} type="text" placeholder={Constants.descrizionePlaceholder} defaultValue={props.dataSource.invoice_to_direct_customer!=null && firstLoad == null?props.dataSource.invoice_to_direct_customer.business_name:item.business_name} disabled={true} required/>
                                                <br/>
                                                <Form.Label>{Constants.terminiPagamento}</Form.Label>
                                                <Form.Control id={'payment_' + categoryName} as="select" defaultValue={props.dataSource.invoice_to_direct_customer!=null && firstLoad == null?props.dataSource.invoice_to_direct_customer.payment_type:item.payment_type} disabled={true}>
                                                    <option value="">{Constants.seleziona}</option>
                                                    {payment}
                                                </Form.Control>
                                            </>
                                            :
                                            <>
                                                <Form.Label>{Constants.business_name}</Form.Label>
                                                <Form.Control id={'ragione_sociale_' + categoryName} type="text" placeholder={Constants.descrizionePlaceholder} defaultValue={props.dataSource.invoice_to_agency!=null && firstLoad == null?props.dataSource.invoice_to_agency.business_name:item.business_name} disabled={true} required/>
                                                <br/>
                                                <Form.Label>{Constants.terminiPagamento}</Form.Label>
                                                <Form.Control id={'payment_' + categoryName} as="select" defaultValue={props.dataSource.invoice_to_agency!=null && firstLoad == null?props.dataSource.invoice_to_agency.payment_type:item.payment_type} disabled={true}>
                                                    <option value="">{Constants.seleziona}</option>
                                                    {payment}
                                                </Form.Control>
                                            </>

                                        }
                                        
                                    </Jumbotron>
                                </Col>
                            );
                        }
                        else{
                            return(   
                                <Col key ={index} className="centered">
                                    <span style={{color:'#ccc'}}>
                                        <FontAwesomeIcon style={{marginRight:40, fontSize:30}} icon="box-open"/> 
                                        {Constants.noPaymentFound}    
                                    </span>
                                </Col>
                            );
                        }
                    }
                })
            }
        }
        else
            return null
    }

    const loadPaymentMethods = (type = null, value = null, firstLoad = null) => {
        let agencyVal;
        let customerVal;
        if(type  == Constants.agenzia && value != null && value!= ""){
            agencyVal = value;
            tempAgencyInvoice = value;
        }
        else{
            agencyVal = document.getElementById('agency').value;
        }
            
        if(type  == Constants.cliente && value != null && value!= ""){
            customerVal = value;
        }
        else{
            customerVal = document.getElementById('customers').value;
        }
            

        let PaymentCards = null;
        let firstCheck = null;
        if(firstLoad == undefined){
            if(props.dataSource.invoice_to_agency != null)
                firstCheck = Constants.agenzia;
            else{
                if(props.dataSource.invoice_to_direct_customer != null)
                    firstCheck = Constants.cliente;
            }
        }

        if(globalState.isMobile){
            PaymentCards =
            <>
                <h4>{Constants.fatturazione}</h4>
                <hr/>
                <Row>
                    {createPaymentCard(fullAgency, agencyVal, Constants.agenzia, firstLoad, firstCheck)}
                </Row>
                <br/>
                <Row>
                    {createPaymentCard(fullCustomers, customerVal, Constants.cliente, firstLoad, firstCheck)}
                </Row>
            </>
        }
        else{
            PaymentCards = 
            <>
                <h4>{Constants.fatturazione}</h4>
                <hr/>
                <Row style={{marginTop:10}}>
                    {createPaymentCard(fullAgency, agencyVal, Constants.agenzia, firstLoad, firstCheck)}
                    {createPaymentCard(fullCustomers, customerVal, Constants.cliente, firstLoad, firstCheck)}
                </Row>
            </>
        }

        setPaymentMethods(PaymentCards);
    }

    


    const deletePosition = (fixed, elementPosition) =>{
        document.getElementById('pos' + elementPosition).value = "";
        document.getElementById((fixed?'fix':'unfix') + 'posDateStart' + elementPosition).value = "";
        document.getElementById((fixed?'fix':'unfix') + 'posDateEnd' + elementPosition).value = "";
        document.getElementById((fixed?'fix':'unfix') + 'posHourStart' + elementPosition).value = "";
        document.getElementById((fixed?'fix':'unfix') + 'posHourEnd' + elementPosition).value = "";
        document.getElementById('posSell' + elementPosition).value = "";
        document.getElementById('posBack' + elementPosition).value = "";
        document.getElementById('posImpression' + elementPosition).value = "";
        let oldObj = {...positionElement};
        if(fixed)
            oldObj.fixed.splice(elementPosition, 1)
        else
            oldObj.unfixed.splice(elementPosition, 1)
            
        setPositionElement(oldObj);
        setModalDeletePosition();
    }   

    const loadPositions = (fixed, item, index) =>{
        return(
            <PositionAccordion 
            campaignStatus ={props.dataSource.states_campaign!=null?props.dataSource.states_campaign.id:null}
            key={index}
            positionsFixed={positionsFixed}
            positionsUnfixed = {positionsUnfixed}
            fixed={fixed}
            isCreate={props.isCreate} 
            index={index} 
            isEdit={props.isEdit}
            setModalDeletePosition={()=>setModalDeletePosition(()=>loadDeletePos(()=>deletePosition(fixed, index)))}
            loadCopyPos = {()=>loadCopyPos(fixed, item)}
            item={item}
            campaignBeginningDate={props.dataSource.beginning_date!=null?props.dataSource.beginning_date:null}
            campaignEndDate={props.dataSource.end_date!=null?props.dataSource.end_date:null}
            positionElement={positionElement}
            setPositionElement={setPositionElement}/>
        );
    }

    const addElement = (fixed) =>{
        let tempObj = {
            position:null,
            beginning_date:null,
            end_date:null,
            cpm_sale:null,
            cpm_back:null,
            forfait_sale:null,
            forfait_back:null,
            impression:null
        }

        let oldelement = {...positionElement}

        if(fixed){
            //if(oldelement.fixed.length <= 4)
                oldelement.fixed.push(tempObj)
            //else
                //setGlobalState({showAlert:true, messageAlert:Constants.maxPosition, typeAlert:'warning'});
        }
        else{
            //if(oldelement.unfixed.length <= 4)
                oldelement.unfixed.push(tempObj)
            //else
                //setGlobalState({showAlert:true, messageAlert:Constants.maxPosition, typeAlert:'warning'});
        }
        setPositionElement(oldelement);
    }

    const verifySector = (e) =>{
        //if(e.target.value == '' || e.target.value == null)
        if(document.getElementById('switch_' + Constants.cliente)!= null)
            document.getElementById('switch_' + Constants.cliente).checked=false;
    }

    const loadAdModal = () =>{
        return(
            <Modal onHide={(e)=>{return}} style={{backgroundColor:'rgba(0,0,0,0.3)'}} show={true} centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Constants.importAdManager}
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                            <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>setModalAdManager()}>
                                <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                            </Button>
                        </OverlayTrigger>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Constants.importAdManagerQuestion}
                </Modal.Body>
                <Modal.Footer>
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.annulla}</Tooltip>}>
                        <Button variant="secondary" onClick={()=>setModalAdManager()}>{Constants.annulla}</Button>
                    </OverlayTrigger>
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.importAdManager}</Tooltip>}>
                        <Button variant="success" onClick={()=>ApiImportInAdManager(Constants.importToAdManager.replace('*', props.dataSource.id), 'POST')}>{Constants.importAdManager}</Button>
                    </OverlayTrigger>
                </Modal.Footer>
            </Modal>
        );
    }

    const copyLinkToShare = () =>{
        var input = document.createElement('textarea');
        input.value = Constants.shareLink + props.dataSource.unique_code;
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.successCopyCampaingLink, typeAlert:'primary'});
    }

    const loadDeletePos = (deletePos) =>{
        return(
            <Modal onHide={(e)=>{return}} style={{backgroundColor:'rgba(0,0,0,0.3)'}} show={true} centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Constants.elimina}
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                            <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>setModalDeletePosition()}>
                                <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                            </Button>
                        </OverlayTrigger>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Constants.deleteQuestion}
                </Modal.Body>
                <Modal.Footer>
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.annulla}</Tooltip>}>
                        <Button variant="secondary" onClick={()=>setModalDeletePosition()}>{Constants.annulla}</Button>
                    </OverlayTrigger>
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.elimina}</Tooltip>}>
                        <Button variant="danger" onClick={()=>deletePos()}>{Constants.elimina}</Button>
                    </OverlayTrigger>
                </Modal.Footer>
            </Modal>
        );
    }

    const loadCopyPos = (fixed, item) =>{
        setModalCopyPosition(
        <CopyPosition 
            campaignStatus ={props.dataSource.states_campaign!=null?props.dataSource.states_campaign.id:null}
            setModalCopyPosition = {()=>setModalCopyPosition()}
            positionsFixed={positionsFixed}
            positionsUnfixed = {positionsUnfixed}
            fixed={fixed}
            isCreate={props.isCreate} 
            isEdit={props.isEdit}
            item={item}
            campaignBeginningDate={props.dataSource.beginning_date!=null?props.dataSource.beginning_date:null}
            campaignEndDate={props.dataSource.end_date!=null?props.dataSource.end_date:null}
            positionElement={positionElement}
            setPositionElement={setPositionElement}/>    
        );
    }

    const showInAdManager = (dfp_id) =>{
        window.open(Constants.goToAD.replace('*', Constants.networkID) + dfp_id);
    }

    const setVenditoreSecondario = (e) =>{
        if(e.target.checked)
            setVenditoreSecondarioVisible(true);
        else
            setVenditoreSecondarioVisible(false);
    }

    const loadBodyModal = () =>{
        return(
            <Form.Group>
                <br/>
                <Form.Label>{Constants.description}</Form.Label>
                <Form.Control id="description" type="text" placeholder={Constants.descrizionePlaceholder} defaultValue={props.dataSource.description} disabled={!props.isEdit} required/>
                <br/>
                <Tabs activeKey={key} onSelect={k => {setKey(k);}} style={{position:'relative'}}>
                    <Tab eventKey={1} title={"Info Campagna"}>
                        <br/>
                        {!props.isCreate?
                            <> 
                                {globalState.isMobile?
                                    <>
                                       
                                            <Form.Label>{Constants.states_campaign}</Form.Label>
                                            <Form.Control id="states_campaign"as="select" disabled={!props.isEdit} defaultValue={props.dataSource.states_campaign!=null?props.dataSource.states_campaign.id:''}>
                                                <option value="">{Constants.seleziona}</option>
                                                {status}
                                            </Form.Control>
                                            <br/>
                                            {!globalState.groups.includes(Constants.underground)?
                                                <>
                                                <Form.Label>{Constants.impressionBooked}</Form.Label>
                                                <Form.Control type="text" defaultValue={props.dataSource.impression_booked!=null?props.dataSource.impression_booked:''} disabled={true}/>
                                                <br/>
                                                </>
                                                :null
                                            }
                                            <Form.Label>{Constants.creatore}</Form.Label>
                                            <Form.Control type="text" defaultValue={props.dataSource.user!=null?props.dataSource.user.first_name + " " + props.dataSource.user.last_name:''} disabled={true}/>
                                            <br/>
                                        {!globalState.groups.includes(Constants.underground)?
                                            <>
                                                
                                                <Form.Label>{Constants.dataCreazioneCampagna}</Form.Label>
                                                <Form.Control type="text" defaultValue={props.dataSource.date_creations!=null?moment(props.dataSource.date_creations, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY - HH:mm"):''} disabled={true}/>
                                                <br/>
                                                <Form.Label>{Constants.dataModificaCampagna}</Form.Label>
                                                <Form.Control type="text" defaultValue={props.dataSource.date_latest_modification!=null?moment(props.dataSource.date_latest_modification, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY - HH:mm"):''} disabled={true}/>
                                                <br/>
                                                <Form.Label>{Constants.utenteUltimaModifica}</Form.Label>
                                                <Form.Control type="text" defaultValue={props.dataSource.user_latest_modification!=null?props.dataSource.user_latest_modification.first_name + " " + props.dataSource.user_latest_modification.last_name:''} disabled={true}/>
                                                <br/>
                                            </>
                                            :null
                                        }
                                    </>
                                    :
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Label>{Constants.states_campaign}</Form.Label>
                                                <Form.Control id="states_campaign"as="select" disabled={!props.isEdit} defaultValue={props.dataSource.states_campaign!=null?props.dataSource.states_campaign.id:''}>
                                                    <option value="">{Constants.seleziona}</option>
                                                    {status}
                                                </Form.Control>
                                            </Col>
                                            {!globalState.groups.includes(Constants.underground)?
                                                <Col>
                                                    <Form.Label>{Constants.impressionBooked}</Form.Label>
                                                    <Form.Control type="text" defaultValue={props.dataSource.impression_booked!=null?props.dataSource.impression_booked:''} disabled={true}/>
                                                </Col>
                                                :null
                                            }
                                            <Col>
                                                <Form.Label>{Constants.creatore}</Form.Label>
                                                <Form.Control type="text" defaultValue={props.dataSource.user!=null?props.dataSource.user.first_name + " " + props.dataSource.user.last_name:''} disabled={true}/>
                                            </Col>
                                        </Row>
                                        <br/>
                                        {!globalState.groups.includes(Constants.underground)?
                                            <>
                                                <Row>
                                                    
                                                    <Col>
                                                        <Form.Label>{Constants.dataCreazioneCampagna}</Form.Label>
                                                        <Form.Control type="text" defaultValue={props.dataSource.date_creations!=null?moment(props.dataSource.date_creations, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY - HH:mm"):''} disabled={true}/>
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>{Constants.dataModificaCampagna}</Form.Label>
                                                        <Form.Control type="text" defaultValue={props.dataSource.date_latest_modification!=null?moment(props.dataSource.date_latest_modification, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY - HH:mm"):''} disabled={true}/>
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>{Constants.utenteUltimaModifica}</Form.Label>
                                                        <Form.Control type="text" defaultValue={props.dataSource.user_latest_modification!=null?props.dataSource.user_latest_modification.first_name + " " + props.dataSource.user_latest_modification.last_name:''} disabled={true}/>
                                                    </Col>
                                                </Row>
                                                <br/>
                                            </>
                                            :null
                                        }
                                    </>
                                }
                                
                                {globalState.isMobile?
                                    <>
                                        <Row>
                                        {!globalState.groups.includes(Constants.underground)?
                                            <Col>
                                                <Form.Label>{Constants.importo}</Form.Label>
                                                <InputGroup>
                                                    <Form.Control id="importo" type="text" defaultValue={props.dataSource.amount} disabled={true}/>
                                                    <InputGroup.Append>
                                                    <InputGroup.Text>€</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Col>
                                            :null
                                        }
                                            <Col>
                                                <Form.Label>{Constants.importoGiro}</Form.Label>
                                                <InputGroup>
                                                    <Form.Control id="importo_giro" type="text" defaultValue={props.dataSource.amount_back} disabled={true}/>
                                                    <InputGroup.Append>
                                                    <InputGroup.Text>€</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col>
                                                <Form.Label>{Constants.dataInizio}</Form.Label>
                                                <Form.Control id="startDateModal" type="date" defaultValue={props.dataSource.beginning_date} disabled={true} onChange={()=>verifyDateModal()} required/>
                                            </Col>
                                            <Col>
                                                <Form.Label>{Constants.dataFine}</Form.Label>
                                                <Form.Control id="endDateModal" type="date" defaultValue={props.dataSource.end_date} disabled={true} onChange={()=>verifyDateModal()} required/>
                                            </Col>
                                        </Row>
                                        <br/>
                                        {!globalState.groups.includes(Constants.underground)?
                                            <Row>
                                                <Col lg="4">
                                                    <Form.Label>{Constants.codiceUnivoco}</Form.Label>
                                                    <Form.Control type="text" defaultValue={props.dataSource.unique_code} disabled={true}/>
                                                </Col>
                                            </Row>
                                            :null
                                        }
                                    </>
                                    :
                                    <>
                                        <Row>
                                        {!globalState.groups.includes(Constants.underground)?
                                            <Col>
                                                <Form.Label>{Constants.importo}</Form.Label>
                                                <InputGroup>
                                                    <Form.Control id="importo" type="text" defaultValue={props.dataSource.amount} disabled={true}/>
                                                    <InputGroup.Append>
                                                    <InputGroup.Text>€</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Col>
                                            :null
                                        }
                                            <Col>
                                                <Form.Label>{Constants.importoGiro}</Form.Label>
                                                <InputGroup>
                                                    <Form.Control id="importo_giro" type="text" defaultValue={props.dataSource.amount_back} disabled={true}/>
                                                    <InputGroup.Append>
                                                    <InputGroup.Text>€</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            {!globalState.groups.includes(Constants.underground)?
                                                <Col>
                                                    <Form.Label>{Constants.codiceUnivoco}</Form.Label>
                                                    <Form.Control type="text" defaultValue={props.dataSource.unique_code} disabled={true}/>
                                                </Col>
                                                :null
                                            }
                                            <Col>
                                                <Form.Label>{Constants.dataInizio}</Form.Label>
                                                <Form.Control id="startDateModal" type="date" defaultValue={props.dataSource.beginning_date} disabled={true} onChange={()=>verifyDateModal()} required/>
                                            </Col>
                                            <Col>
                                                <Form.Label>{Constants.dataFine}</Form.Label>
                                                <Form.Control id="endDateModal" type="date" defaultValue={props.dataSource.end_date} disabled={true} onChange={()=>verifyDateModal()} required/>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <br/>
                            </>
                        :null
                        }
                        {globalState.isMobile?
                            <>
                             
                                <Form.Label>{Constants.purchase_order}</Form.Label>
                                <Form.Control id="purchase_order" placeholder={Constants.purchaseOrderPlaceholder} type="text" defaultValue={props.dataSource.purchase_order} disabled={!props.isEdit}/>
                                <br/>
                                <Form.Label>{Constants.referent}</Form.Label>
                                <Form.Control id="referent" type="text" placeholder={Constants.referentePlaceholder} defaultValue={props.dataSource.referent} disabled={!props.isEdit} required/>
                                <br/>
                                {!globalState.groups.includes(Constants.underground)?
                                    <Form.Check custom id="checkVenditoreSecondario" type="checkbox" label={Constants.venditoreSecondarioQuestion} onChange={(e)=>setVenditoreSecondario(e)} defaultChecked={props.dataSource.secondary_seller!=null?true:false} disabled={!props.isEdit} />
                                    :null
                                }
                            </>
                            :
                            <Row>
                                <Col>
                                    <Form.Label>{Constants.purchase_order}</Form.Label>
                                    <Form.Control id="purchase_order" placeholder={Constants.purchaseOrderPlaceholder} type="text" defaultValue={props.dataSource.purchase_order} disabled={!props.isEdit}/>
                                </Col>
                                <Col>
                                    <Form.Label>{Constants.referent}</Form.Label>
                                    <Form.Control id="referent" type="text" placeholder={Constants.referentePlaceholder} defaultValue={props.dataSource.referent} disabled={!props.isEdit} required/>
                                </Col>
                                {!globalState.groups.includes(Constants.underground)?
                                    <Col style={{paddingTop:40}}>
                                        <Form.Check custom id="checkVenditoreSecondario" type="checkbox" label={Constants.venditoreSecondarioQuestion} onChange={(e)=>setVenditoreSecondario(e)} defaultChecked={props.dataSource.secondary_seller!=null?true:false} disabled={!props.isEdit} />
                                    </Col>
                                    :null
                                }
                            </Row>
                        }
                        
                        {!globalState.groups.includes(Constants.underground)?
                            <>
                            {globalState.isMobile?
                                <>
                                    {venditoreSecondarioVisible?
                                        <>
                                            <br/>
                                            <Form.Label>{Constants.venditoreSecondario}</Form.Label>
                                            <Form.Control defaultValue={props.dataSource.secondary_seller!=null?props.dataSource.secondary_seller.id:''} id="secondarySeller" as="select" disabled={!props.isEdit}>
                                                <option value="">{Constants.seleziona}</option>
                                                {secondarySeller}
                                            </Form.Control>
                                            <br/>
                                            <Form.Label>{Constants.percentualeVenditoreSecondario}</Form.Label>
                                            <InputGroup>
                                            <Form.Control id="percentualeVenditoreSecondario" onInput={(e)=>(e.target.value>100 || e.target.value<0)?e.target.value = "":""} type="number" placeholder={Constants.percentualeVenditoreSecondarioPlaceholder} defaultValue={props.dataSource.secondary_seller_percentage!=null?props.dataSource.secondary_seller_percentage:50} disabled={!props.isEdit}/>
                                                <InputGroup.Append>
                                                <InputGroup.Text>%</InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </>
                                        :null
                                    }
                                </>
                                :
                                <>
                                {venditoreSecondarioVisible?
                                    <>
                                        <br/>
                                        <Row>
                                            <Col>
                                                <Form.Label>{Constants.venditoreSecondario}</Form.Label>
                                                <Form.Control defaultValue={props.dataSource.secondary_seller!=null?props.dataSource.secondary_seller.id:''} id="secondarySeller" as="select" disabled={!props.isEdit}>
                                                    <option value="">{Constants.seleziona}</option>
                                                    {secondarySeller}
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Label>{Constants.percentualeVenditoreSecondario}</Form.Label>
                                                <InputGroup>
                                                <Form.Control id="percentualeVenditoreSecondario" onInput={(e)=>(e.target.value>100 || e.target.value<0)?e.target.value = "":""} type="number" placeholder={Constants.percentualeVenditoreSecondarioPlaceholder} defaultValue={props.dataSource.secondary_seller_percentage!=null?props.dataSource.secondary_seller_percentage:50} disabled={!props.isEdit}/>
                                                    <InputGroup.Append>
                                                    <InputGroup.Text>%</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <br/>
                                    </>
                                    :null
                                }
                                </>
                            }   
                            
                            </>
                            :null
                        }
                        <br/>
                        {!globalState.groups.includes(Constants.underground)?
                            <> 
                                {globalState.isMobile?
                                    <>
                                        <Form.Label>{Constants.nota}</Form.Label>
                                        <Form.Control id="nota" as="textarea" rows="5" placeholder={Constants.notaPlaceholder} defaultValue={props.dataSource.note} disabled={!props.isEdit}/>
                                        <br/>
                                        <Form.Label>{Constants.notaCliente}</Form.Label>
                                        <Form.Control id="nota_cliente" as="textarea" rows="5" placeholder={Constants.notaPlaceholder} defaultValue={props.dataSource.note_cliente} disabled={!props.isEdit}/>
                                    </>
                                    :
                                    <Row>
                                        <Col>
                                            <Form.Label>{Constants.nota}</Form.Label>
                                            <Form.Control id="nota" as="textarea" rows="5" placeholder={Constants.notaPlaceholder} defaultValue={props.dataSource.note} disabled={!props.isEdit}/>
                                        </Col>
                                        <Col>
                                            <Form.Label>{Constants.notaCliente}</Form.Label>
                                            <Form.Control id="nota_cliente" as="textarea" rows="5" placeholder={Constants.notaPlaceholder} defaultValue={props.dataSource.note_cliente} disabled={!props.isEdit}/>
                                        </Col>
                                    </Row>
                                }
                                
                                <br/>
                                <Form.Label>{Constants.sconto}</Form.Label>
                                <InputGroup>
                                <Form.Control id="sconto" type="number" onChange={(e)=>{document.getElementById('motivazione_sconto-container').style.display =(e.target.value > 0)?'block':'none'; if(e.target.value == 0)document.getElementById('motivazione_sconto').value = '';}} placeholder={Constants.placeholderSconto} defaultValue={props.dataSource.discount} disabled={!props.isEdit}/>
                                    <InputGroup.Append>
                                    <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                <br/>
                                <div id="motivazione_sconto-container" style={{display:props.dataSource.discount>0?"block":"none"}}>
                                    <Form.Label>{Constants.motivazioneSconto}</Form.Label>
                                    <Form.Control id="motivazione_sconto" as="textarea" rows="3" placeholder={Constants.placeholderMotivazioneSconto} defaultValue={props.dataSource.discount_reason} disabled={!props.isEdit}/> 
                                </div>
                            </>
                            :null
                        }
                    </Tab>
                    <Tab eventKey={2} title={"Fatturazione"}>
                        <br/>
                        {!globalState.groups.includes(Constants.underground)?
                            <>  
                                <Form.Check custom id="diretta" type="checkbox" label={Constants.diretta} onChange={(e)=>{directChange(e); loadPaymentMethods(null, null, true);}} defaultChecked={props.dataSource.is_direct} disabled={!props.isEdit} />
                                <br/>
                                <Row>
                                    <Col>
                                        <Form.Label>{Constants.agenzia}</Form.Label>
                                        <Form.Control onChange={(e)=>agencyChange(e)} defaultValue={props.dataSource.agency!=null?props.dataSource.agency.id:''} id="agency" as="select" disabled={!props.isEdit}>
                                            <option value="">{Constants.seleziona}</option>
                                            {agency}
                                        </Form.Control>
                                    </Col>
                                    <Col id="container_media_center_category" style={{display:props.dataSource.agency!=null?'block':'none'}}>
                                        <Form.Label>{Constants.categoriaCentromedia}</Form.Label>
                                        <Form.Control onChange={(e)=>categoriaCentromedia(e)}defaultValue={props.dataSource.media_center_category!=null?props.dataSource.media_center_category:''} id="media_center_category" as="select" disabled={!props.isEdit}>
                                            <option value="">{Constants.seleziona}</option>
                                            <option value="0">Adv Reservation</option>
                                            <option value="1">Content</option>
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col>
                                        <Form.Label>{Constants.cliente}</Form.Label>
                                        <Form.Control defaultValue={props.dataSource.direct_customer!=null?props.dataSource.direct_customer.id:''} onChange={(e)=>{e.target.value!=""?serviceApiGetSettori((Constants.getSettoriPerIdCliente+ '?size=9999').replace('*', e.target.value),'GET'):setSectors([]); loadPaymentMethods(null, null, true);}} id="customers" as="select" disabled={!props.isEdit}>
                                            <option value="">{Constants.seleziona}</option>
                                            {customers}
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label>{Constants.settore}</Form.Label>
                                        <Form.Control defaultValue={props.dataSource.sector!=null?props.dataSource.sector.id:''} id="sectors" as="select" disabled={!props.isEdit} onChange={(e)=>verifySector(e)}>
                                            <option value="">{Constants.seleziona}</option>
                                            {sectors}
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <br/><br/>
                                {paymentMethods}
                                <br/>
                            </>
                            :null
                        }
                    </Tab>
                    <Tab eventKey={3} title={"Posizioni"}>
                        <br/>
                        {globalState.isMobile?
                            <>  
                                <h4>{Constants.posizione + " " + Constants.fissa}</h4>
                                <hr/>
                                <div>
                                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.nuovaPosizione + " " + Constants.fissa}</Tooltip>}>
                                        <Button style={{marginBottom:20}} variant="default" onClick={()=>{addElement(true);}} disabled={!props.isEdit}>
                                            <FontAwesomeIcon style={{marginRight:10}} icon="plus-circle"/>
                                            {Constants.nuovaPosizione + " " + Constants.fissa}
                                        </Button>
                                    </OverlayTrigger>
                                    <br/>
                                    {positionBoxFixed}
                                </div>
                                <br/>
                                <h4>{Constants.posizione + " " + Constants.nonFissa}</h4>
                                <hr/>
                                <div>
                                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.nuovaPosizione + " " + Constants.nonFissa}</Tooltip>}>
                                        <Button style={{marginBottom:20}} variant="default" onClick={()=>{addElement(false);}} disabled={!props.isEdit}>
                                            <FontAwesomeIcon style={{marginRight:10}} icon="plus-circle"/>
                                            {Constants.nuovaPosizione + " " + Constants.nonFissa}
                                        </Button>
                                    </OverlayTrigger>
                                    {positionBoxUnfixed}
                                </div>
                            </>
                            :
                            <>
                                <Row>
                                    <Col>
                                        <h4>{Constants.posizione + " " + Constants.fissa}</h4>
                                    </Col>
                                    <Col>
                                        <h4>{Constants.posizione + " " + Constants.nonFissa}</h4>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col>
                                        <div>
                                            <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.nuovaPosizione + " " + Constants.fissa}</Tooltip>}>
                                                <Button style={{marginBottom:20}} variant="default" onClick={()=>{addElement(true);}} disabled={!props.isEdit}>
                                                    <FontAwesomeIcon style={{marginRight:10}} icon="plus-circle"/>
                                                    {Constants.nuovaPosizione + " " + Constants.fissa}
                                                </Button>
                                            </OverlayTrigger>
                                            <br/>
                                            {positionBoxFixed}
                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.nuovaPosizione + " " + Constants.nonFissa}</Tooltip>}>
                                                <Button style={{marginBottom:20}} variant="default" onClick={()=>{addElement(false);}} disabled={!props.isEdit}>
                                                    <FontAwesomeIcon style={{marginRight:10}} icon="plus-circle"/>
                                                    {Constants.nuovaPosizione + " " + Constants.nonFissa}
                                                </Button>
                                            </OverlayTrigger>
                                            {positionBoxUnfixed}
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        
                    </Tab>
                    {props.dataSource.states_campaign != null && props.dataSource.dfp_id != null?
                        !props.isEdit && (props.dataSource.states_campaign.id == 6 || props.dataSource.states_campaign.id == 4)?
                            <Tab eventKey={4} title={"Statistiche"} id="statistics-tab"> 
                                <br/>
                                <div style={{position:'relative', width:'100%', textAlign:'center'}}>
                                    <h4>{Constants.statistiche}</h4>
                                    <br/>
                                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.aggiorna}</Tooltip>}>
                                        <Button variant="default" onClick={()=>updateStatistics(Constants.updateStatistics.replace('*', props.dataSource.dfp_id), "GET")}>
                                            <FontAwesomeIcon icon="sync-alt"/> Aggiorna Statistiche<br/>
                                            <small>questa operazione potrebbe richiedere qualche minuto</small>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                                <hr/>
                                {statistics}
                            </Tab>
                            :
                            null
                        :
                        null
                    }
                </Tabs>
            </Form.Group>
        );
    }

    const download = (filename, text) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
    }

    return (
        <>
        <Modal onHide={(e)=>{return}} show={props.visible} size="xl"  centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                    {!globalState.groups.includes(Constants.underground) && process.env.REACT_APP_CUSTOM != null?
                        <>
                            {!props.isEdit && props.dataSource.states_campaign != null?
                                <>
                                    {props.dataSource.states_campaign.id == 6?
                                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.exportGO}</Tooltip>}>
                                            <Button style={{right:355, top:18, position:'absolute'}} variant="success" onClick={()=>{
                                                if(fileGOdata != null)
                                                    download("FATTURE.txt", fileGOdata);
                                            }}>
                                                {Constants.exportGO}
                                            </Button>
                                        </OverlayTrigger>
                                        :null
                                    }
                                </>
                                :null
                            }
                        </>
                        :null
                    }

                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.chiudi}</Tooltip>}>
                        <Button style={{right:17, top:17, position:'absolute'}} variant="danger" onClick={()=>{props.dataSource.id==undefined?setModalConfirmSave(loadConfirmSave()):closeAll()}}>
                            <FontAwesomeIcon style={{fontSize:30}} icon="times"/>
                        </Button>
                    </OverlayTrigger>
                    {!props.isCreate?
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.copiaLink}</Tooltip>}>
                            <Button variant='secondary' style={{right:70, top:17, position:'absolute'}} onClick={()=>copyLinkToShare()}>
                                <FontAwesomeIcon icon='paperclip'/>
                            </Button>
                        </OverlayTrigger>
                        :
                        null
                    }
                    {!globalState.groups.includes(Constants.underground)?
                        <>
                            {globalState.permissions.siteMenu.ADaction.import != undefined && props.dataSource.dfp_id==null?
                                <>
                                    {!props.isEdit ?
                                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.importAdManager}</Tooltip>}>
                                            <Button variant='warning' style={{right:125, top:18, position:'absolute'}} onClick={()=>setModalAdManager(loadAdModal())}>
                                                <FontAwesomeIcon style={{marginRight:globalState.isMobile?0:10}} icon='file-import'/>
                                                {globalState.isMobile?'':Constants.importAdManager}
                                            </Button>
                                        </OverlayTrigger>
                                        :null
                                    }
                                </>
                                :null
                            }

                            {globalState.permissions.siteMenu.ADaction.show != undefined && props.dataSource.dfp_id!=null?
                                <>
                                    
                                    {!props.isEdit ?
                                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.viewonADManager}</Tooltip>}>
                                            <Button variant='success' style={{right:125, top:18, position:'absolute'}} onClick={()=>showInAdManager(props.dataSource.dfp_id)}>
                                                <FontAwesomeIcon style={{marginRight:globalState.isMobile?0:10}} icon='eye'/>
                                                {globalState.isMobile?'':Constants.viewonADManager}
                                            </Button>
                                        </OverlayTrigger>
                                        :null
                                    }
                                </>
                                :null
                            }
                        </>
                        :null
                    }
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body className="body-modal-campaign">
                    {loadBodyModal()}
                </Modal.Body>
                <Modal.Footer style={{position:'relative'}}>
                    {key < 4?
                        <div style={{position:'absolute', left:20, top:12}}>
                            {key > 1?
                                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{"Indietro"}</Tooltip>}>
                                    <Button onClick={()=>setKey((parseInt(key) - 1))} variant="primary">{globalState.isMobile?"<":"< Indietro"}</Button>
                                </OverlayTrigger>
                                :null
                            }
                            {key < 3?
                                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{"Avanti"}</Tooltip>}>
                                    <Button onClick={()=>setKey((parseInt(key) + 1))} style={{marginLeft:20}} variant="primary">{globalState.isMobile?">":"Avanti >"}</Button>
                                </OverlayTrigger>
                                :null
                            }
                        </div>
                        :null
                    }

                    {key == 4?
                        <>
                            {statistics != null?
                                <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.downloadStatistics}</Tooltip>}>
                                    <a href={process.env.REACT_APP_BASE_URL + "campaigns/"+props.dataSource.id+"/statistics/download/"}>
                                        <Button variant="success">
                                            <FontAwesomeIcon style={{marginRight:10}} icon="file-excel"/>
                                            {Constants.downloadStatistics}
                                        </Button>
                                    </a>
                                </OverlayTrigger>
                                :null
                            }
                        </>
                        :null
                    }
                    
                    
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.annulla}</Tooltip>}>
                        <Button variant="secondary" onClick={()=>{props.dataSource.id==undefined?setModalConfirmSave(loadConfirmSave()):closeAll()}}>{Constants.annulla}</Button>
                    </OverlayTrigger>
                    {!globalState.isMobile?
                        <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.salvaContinua}</Tooltip>}>
                            <Button type="submit" variant="primary" onClick={(e)=>{saveData(e, 1);}} style={{display:!props.isEdit?'none':'block'}} disabled={!props.isEdit}>{Constants.salvaContinua}</Button>
                        </OverlayTrigger>
                        :null
                    }
                    <OverlayTrigger delay={{ show: 300, hide: 300 }} overlay={<Tooltip id="tooltip-disabled">{Constants.salva}</Tooltip>}>
                        <Button type="submit" variant="primary" onClick={(e)=>{saveData(e, 2);}} style={{display:!props.isEdit?'none':'block'}} disabled={!props.isEdit}>{Constants.salva}</Button>
                    </OverlayTrigger>
                </Modal.Footer>
            </Form>
        </Modal>
        {modalConfirmSave}
        {modalDeletePosition}
        {modalCopyPosition}
        {modalAdManager}
        {modalSaveCampaign}
        </>
    );


    /////////////API//////////////////////////////////

    function ApiImportInAdManager(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:response.data.message, typeAlert:'success'}); 
            closeAll();
        })
        .catch(function (error) {
            if(error.response!=undefined && error.response.status == 500){
                setGlobalState({showSpinner:false, showAlert:true, messageAlert:error.response.data.message, typeAlert:'danger'});
            }
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiSendData(obj, url, method, saveType){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{
                'Content-Type':'application/json', 
                'Authorization':'Bearer ' + globalState.accessToken,
                'Accept-Language': 'it'
            },
            data:obj
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.successMessage, typeAlert:'success'});
            if(saveType == 1)
                reloadSavedCampaign(Constants.getCampagne + '?id=' + response.data.id, 'GET')
            else
                closeAll();
        })
        .catch(function (error) {
            if(error.response.status == 400){
                let Message = Constants.requiredFieldError;
                var keys = [];
                for (var k in error.response.data){

                    if(k == 'positions_fixed'){
                        Message = error.response.data.positions_fixed[0] + "\n" + Constants.posizione + ": " +  error.response.data.booked_positions[0].position + " nelle seguenti date: "
                        + error.response.data.booked_positions[0].beginning_date + " - " + error.response.data.booked_positions[0].end_date + "\nnella seguente campagna: " + error.response.data.booked_positions[0].description;
                        return setGlobalState({showSpinner:false, showAlert:true, messageAlert:Message, typeAlert:'warning'});
                    }

                    if(k == 'position_incompatible'){
                        Message = Constants.incompatiblePosition + " " + error.response.data.position_incompatible[0];
                        return setGlobalState({showSpinner:false, showAlert:true, messageAlert:Message, typeAlert:'warning'});
                    }
                    else{
                        keys.push(k);
                        if(Constants[k] != null)
                            Message = Message + Constants[k] + ", ";
                        else{
                            Message = "";
                            if(k != null){
                                for (var mk in error.response.data[k][0]){
                                    if(mk != null)
                                        Message += mk + ": " + error.response.data[k][0][mk][0] + ", ";
                                }
                                return setGlobalState({showSpinner:false, showAlert:true, messageAlert:Message, typeAlert:'danger'});
                            }
                        }
                            

                        if(document.getElementById(k) != null)
                            document.getElementById(k).classList.add('setErrorField');
                    }
                }
                setGlobalState({showSpinner:false, showAlert:true, messageAlert:Message.substring(0, Message.length - 2), typeAlert:'warning'});
            }
            else
                setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});

            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function reloadSavedCampaign(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            closeAll();
            props.setIsCreate(false);
            props.setModalTitle(Constants.modifica);
            props.setIsEdit(true);
            props.setModalVisibility(true);
            props.setFieldValue(response.data.results[0]);
        })
        .catch(function (error) {
            if(error.response!=undefined && error.response.status == 429){
                return setGlobalState({showSpinner:false, showAlert:true, messageAlert:error.response.data.message + " " + Constants.attendi + " " + error.response.data.availableIn + " " + Constants.secondi, typeAlert:'danger'});
            }
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }
  
    function updateStatistics(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.successMessage, typeAlert:'success'});
            setStatistics(loadStatistics(response.data.results));
        })
        .catch(function (error) {
            if(error.response!=undefined && error.response.status == 429){
                return setGlobalState({showSpinner:false, showAlert:true, messageAlert:error.response.data.message + " " + Constants.attendi + " " + error.response.data.availableIn + " " + Constants.secondi, typeAlert:'danger'});
            }
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetPosizioni(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            serviceApiGetPosizioniUnused(Constants.getPosition + '?is_visible=True&is_common=False&size=9999', 'GET', response.data)
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetPosizioniUnused(url, method, mostUsed){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            let obj = {...mostUsed, ...response.data};
            setFullPositions(obj)
            populatePositions(response.data, mostUsed)
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetClienti(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setCustomers(populateCustomers(response.data))
            setFullCustomers(response.data)
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetAgency(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setAgency(populateAgency(response.data))
            setFullAgency(response.data)
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetSecondarySeller(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setSecondarySeller(populateSecondarySeller(response.data))
            setFullSecondarySeller(response.data)
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetSettori(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setSectors(populateSectors(response.data));
            if(props.dataSource.sector!=null){
                document.getElementById('sectors').value = props.dataSource.sector.id;
            }
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetStatus(url, method){
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setStatus(populateStatus(response.data));
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }

    function serviceApiGetPayment(url, method){
        setGlobalState({showSpinner:true});
        const options = {
            url: process.env.REACT_APP_BASE_URL + url,
            method: method,
            headers:{'Content-Type':'application/json', 'Authorization':'Bearer ' + globalState.accessToken},
            data:{}
        };
        axios(options).then((response)=> {
            setGlobalState({showSpinner:false});
            setPayment(populatePayment(response.data));
        })
        .catch(function (error) {
            setGlobalState({showSpinner:false, showAlert:true, messageAlert:Constants.genericError, typeAlert:'danger'});
            if(error.response!=undefined && error.response.status == 401){
                setGlobalState({accessToken:null});
                deleteCookie('accessToken');
            }
        })
    }
}